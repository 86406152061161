import React from "react";
import Icon from "@/ui/icon/Icon";

import { Link } from "react-router-dom";

import style from "./Progress.module.css";

function Progress({ index, numberOfQuestions, points }) {
	return (
		<>
			<div className={style.progressHolder}>
				<Link to="/" className={style.back}>
					<Icon icon="fa-times" />
				</Link>
				{Array.from({ length: numberOfQuestions }).map((_, i) => (
					<div
						key={i}
						className={`${style.progressItem} ${
							i < index
								? style.filled
								: i === index
								? style.current
								: ""
						}`}
					></div>
				))}
			</div>
			<div className={style.subheader}>
				<div className={style.infoHolder}>
					<span>
						<Icon icon="fa-location-question" />
					</span>
					<span>{index + 1}</span>
					<span>/</span>
					<span>{numberOfQuestions}</span>
				</div>
				<div className={style.infoHolder}>
					<span>
						<Icon icon="fa-bullseye-arrow" />
					</span>
					<span>{points}</span>
				</div>
			</div>
		</>
	);
}

export default Progress;
