import { useState } from "react";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from "@/ui/dropdownMenu/DropdownMenu";

import style from "./MoreOptions.module.css";

import { successToast } from "@/ui/toast/Toast";
import Icon from "@/ui/icon/Icon";
import { useDeleteCourseReview } from "../../hooks/useDeleteCourseReview";

function MoreOptions({ course }) {
	const { save } = useDeleteCourseReview({ course });

	const handleReviewDelete = async () => {
		await save();
		successToast("Uspješno obrisana recenzija");
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger className={style.moreBtn}>
				<Icon icon="fa-ellipsis" />
			</DropdownMenuTrigger>
			<DropdownMenuContent className={style.menu}>
				<DropdownMenuItem
					className={style.item}
					onSelect={handleReviewDelete}
				>
					<Icon icon="fa-trash" />
					<span>Obriši</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export default MoreOptions;
