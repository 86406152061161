export const BASE_URL =
	import.meta.env.VITE_API_BASE_URL || "http://localhost:8000/";
export const AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME = "auth-token";
export const APP_NAME = "Dekamas";
export const GOOGLE_REDIRECT_URI =
	import.meta.env.VITE_GOOGLE_REDIRECT_URI ||
	"http://localhost:5173/login/google";
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
export const TELEGRAM_BOT_TOKEN =
	"7822857007:AAHAdFuRqUrX_-hf25eOGGHoOjGhCUR_uO4";
export const TELEGRAM_CHAT_ID = "-1002407189733";
