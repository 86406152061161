import { request } from "@/services/network";

export async function getThreads() {
	return request({
		url: `/ai/threads/`,
		method: "GET",
	});
}

export async function getRuns({ thread_uuid }) {
	return request({
		url: `/ai/threads/${thread_uuid}/`,
		method: "GET",
	});
}

export async function getRun({ run_uuid }) {
	return request({
		url: `/ai/runs/${run_uuid}/`,
		method: "GET",
	});
}

export async function getTokenUsage({ comment }) {
	return request({
		url: `/ai/token-usage/`,
		method: "GET",
	});
}

export async function sendMessage({ thread_uuid, unit_uuid, message }) {
	return request({
		url: `/ai/send-message/`,
		method: "POST",
		data: {
			thread_uuid,
			unit_uuid,
			message,
		},
	});
}
