import { toast } from "react-hot-toast";
import style from "./Toast.module.css";
import Icon from "@/ui/icon/Icon";

const durations = {
	success: 5000,
	error: 6000,
};

export const successToast = (message) => {
	const randomIco = [
		"fa-badge-check",
		"fa-ballot-check",
		"fa-check-double",
		"fa-square-check",
	][Math.floor(Math.random() * 4)];
	toast.custom(
		(t) => (
			<div
				className={`${style.body} ${
					t.visible ? style.enter : style.leave
				} ${style.success}`}
			>
				<div className={style.icoHolder}>
					<Icon icon={randomIco} />
				</div>
				<div className={style.info}>
					<p>Obavještenje</p>
					<span>{message}</span>
				</div>
				<button
					onClick={() => toast.dismiss(t.id)}
					className={style.close}
				>
					Zatvori
				</button>
			</div>
		),
		{ duration: durations.success }
	);
};

export const errorToast = (message) => {
	const randomIco = [
		"fa-bomb",
		"fa-circle-exclamation",
		"fa-square-exclamation",
		"fa-triangle-exclamation",
	][Math.floor(Math.random() * 4)];
	toast.custom(
		(t) => (
			<div
				className={`${style.body} ${
					t.visible ? style.enter : style.leave
				} ${style.error}`}
			>
				<div className={style.icoHolder}>
					<Icon icon={randomIco} />
				</div>
				<div className={style.info}>
					<p>Došlo je do greške</p>
					<span>{message}</span>
				</div>
				<button
					onClick={() => toast.dismiss(t.id)}
					className={style.close}
				>
					Zatvori
				</button>
			</div>
		),
		{
			duration: durations.error,
		}
	);
};

export const warningToast = (message) => {
	toast.custom(
		(t) => (
			<div
				className={`${style.body} ${
					t.visible ? style.enter : style.leave
				} ${style.warning}`}
			>
				<div className={style.icoHolder}>
					<Icon icon="fa-message-exclamation" />
				</div>
				<div className={style.info}>
					<p>Obavještenje</p>
					<span>{message}</span>
				</div>
				<button
					onClick={() => toast.dismiss(t.id)}
					className={style.close}
				>
					Zatvori
				</button>
			</div>
		),
		{
			duration: durations.error,
		}
	);
};
