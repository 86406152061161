import style from "./CourseTabs.module.css";

import AboutCourseSection from "@/features/video/components/aboutCourseSection/AboutCourseSection";
import ReviewSection from "@/features/reviews/ReviewSection";
import CommentSection from "@/features/comments/components/commentSection/CommentSection";
import TranscriptSection from "@/features/video/components/transcriptSection/TranscriptSection";
import AskAiButton from "@/features/ai/components/askAiButton/AskAiButton";
import ContentTable from "@/features/video/components/contentTable/ContentTable"; // Import ContentTable

import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@/hooks/useMediaQuery"; // Import useMediaQuery

function CourseTabs({ unit, currentVideoSecond }) {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(0);
	const isMobile = useMediaQuery("(max-width: 1000px)"); // Use useMediaQuery to check for mobile device

	let tabs = [];

	if (isMobile) {
		tabs = [
			{
				title: "Sadržaj",
				content: <ContentTable />,
				path: "content",
			},
			{
				title: "O kursu",
				content: <AboutCourseSection unit={unit} />,
				path: "about",
			},
			{
				title: "Recenzije",
				content: <ReviewSection course={unit?.course} />,
				path: "reviews",
			},
			{
				title: "Postavi pitanje",
				content: <CommentSection video={unit.video} />,
				path: "questions",
			},
			{
				title: "Transkript",
				content: (
					<TranscriptSection
						video={unit.video}
						currentVideoSecond={currentVideoSecond}
					/>
				),
				path: "transcript",
			},
		];
	} else {
		tabs = [
			{
				title: "O kursu",
				content: <AboutCourseSection unit={unit} />,
				path: "about",
			},
			{
				title: "Recenzije",
				content: <ReviewSection course={unit?.course} />,
				path: "reviews",
			},
			{
				title: "Postavi pitanje",
				content: <CommentSection video={unit.video} />,
				path: "questions",
			},
			{
				title: "Transkript",
				content: (
					<TranscriptSection
						video={unit.video}
						currentVideoSecond={currentVideoSecond}
					/>
				),
				path: "transcript",
			},
		];
	}

	useEffect(() => {
		const path = location.hash.replace("#", "");
		const tabIndex = tabs.findIndex((tab) => tab.path === path);
		if (tabIndex !== -1) {
			setActiveTab(tabIndex);
		}
	}, [location.hash, tabs]);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className={style.tabs}>
			<div className={style.toolbar}>
				<Swiper
					direction="horizontal"
					slidesPerView="auto"
					slidesPerGroup={1}
					pagination={{ clickable: true }}
					scrollbar={{
						draggable: true,
					}}
					mousewheel={true}
					className={style.navigation}
				>
					{tabs.map((tab, index) => (
						<SwiperSlide key={index}>
							<Link
								className={`${style.tabHeader} ${
									activeTab === index ? style.active : ""
								}`}
								onClick={() => handleTabClick(index)}
								to={`#${tab.path}`}
							>
								{tab.title}
							</Link>
						</SwiperSlide>
					))}
				</Swiper>
				<AskAiButton currentUnit={unit} />
			</div>
			<div className={style.tabContent}>
				{tabs.map(
					(tab, index) =>
						activeTab === index && (
							<div key={index}>{tab.content}</div>
						)
				)}
			</div>
		</div>
	);
}

export default CourseTabs;
