import ForgotPasswordLayout from "@/features/auth/components/forgotPasswordLayout/ForgotPasswordLayout";
import { useSearchParams } from "react-router-dom";

function ForgotPassword() {
	const [searchParams] = useSearchParams();

	const code = searchParams.get("code");

	return <ForgotPasswordLayout code={code} />;
}

export default ForgotPassword;
