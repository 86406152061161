// SearchControls.jsx
import style from "./SearchControls.module.css";
import ShortcutKey from "@/ui/shortcutKey/ShortcutKey";

function SearchControls() {
	return (
		<div className={style.controls}>
			<ShortcutKey symbols={["up", "down"]} name="Navigacija" />
			<ShortcutKey symbols={["return"]} name="Odaberi" />
			<ShortcutKey symbols={["esc"]} name="Zatvori" />
		</div>
	);
}

export default SearchControls;
