import style from "./LearningPaths.module.css";

import Container from "@/layouts/container/Container";
import Icon from "@/ui/icon/Icon";
import Certificate from "@/ui/certificate/Certificate";

import PathCards from "@/features/paths/components/pathCards/PathCards";

function LearningPaths() {
	return (
		<>
			<Container>
				<div className={style.banner}>
					<div className={style.bannerContent}>
						<div className={style.title}>
							<h2>Putanje učenja</h2>
							<div className={style.icoHolder}>
								<Icon
									icon="fa-star-of-life"
									iconType="fa-solid"
								/>
							</div>
						</div>
						<p>
							Odaberite svoju putanju učenja i steknite veštine
							koje vam trebaju, uz mogućnost dobijanja
							mikroakreditiva!
						</p>
					</div>
					<div className={style.other}>
						<Certificate size={20} background />
						<div className={style.decoration}>
							<Icon icon="fa-scribble" />
						</div>
					</div>
				</div>
				<PathCards />
			</Container>
		</>
	);
}

export default LearningPaths;
