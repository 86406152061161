import style from "./BillingForm.module.css";
import Spinner from "@/features/forms/components/Spinner";
import QueryError from "@/features/admin/components/QueryError";

import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";
import { useSubscribe } from "../hooks/useSubscribe";

import SettingsSection from "@/features/settings/components/settingsSection/SettingsSection";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

import { formatDate, getLabel } from "@/utils/helpers";

function BillingForm({ user }) {
	const { subscription, isPending, isError, error } =
		useSubscriptionDetails();

	const { subscribe, isPending: isSubscribing } = useSubscribe();

	if (isPending) return <Spinner />;
	if (isError) return <QueryError error={error} />;

	const endDate = new Date(subscription?.end_date);
	const startDate = new Date(subscription?.start_date);

	const today = new Date();

	const totalDays = Math.ceil(
		(endDate - startDate) / (1000 * 60 * 60 * 24) + 1
	);
	const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24) + 1);
	const progressWidth =
		totalDays > 0
			? Math.floor(((totalDays - daysLeft) / totalDays) * 100)
			: 100;

	return (
		<SettingsSection
			title="Informacije o vašem planu"
			description="Provjerite status svoje pretplate, produžite ili otkažite pretplatu"
		>
			<div
				className={`${style.plan} ${
					subscription?.is_active ? style.preferred : ""
				}`}
			>
				<div className={style.planHeader}>
					<h3>
						{subscription?.is_active
							? "Premium plan"
							: "Besplatni plan"}
					</h3>
				</div>
				<div className={style.planBody}>
					{subscription?.is_active && (
						<>
							<div className={style.progressInfo}>
								<div className={style.info}>
									<div className={style.icoHolder}>
										<Icon icon="fa-calendar" />
									</div>
									<span>Početak pretplate:</span>
									<span>{formatDate(startDate)}</span>
								</div>
								<div className={style.info}>
									<div className={style.icoHolder}>
										<Icon icon="fa-flag-checkered" />
									</div>
									<span>Kraj pretplate:</span>
									<span>{formatDate(endDate)}</span>
								</div>
							</div>
							<div className={style.progress}>
								<div
									className={style.progressFill}
									style={{ width: `${progressWidth}%` }}
								></div>
								<span>{Math.round(progressWidth)}%</span>
							</div>
						</>
					)}
				</div>
				<div className={style.planFooter}>
					{subscription?.is_active && (
						<div className={style.daysLeft}>
							<span>
								{getLabel(daysLeft, {
									singular: "Preostao vam je:",
									dual: "Preostala su vam:",
									plural: "Preostalo vam je:",
								})}{" "}
							</span>
							<div className={style.days}>
								{daysLeft}{" "}
								<span>
									{getLabel(daysLeft, {
										singular: "dan",
										dual: "dana",
										plural: "dana",
									})}
								</span>
							</div>
						</div>
					)}
					<div className={style.buttonHolder}>
						<Button className={style.btn} onClick={subscribe}>
							{isSubscribing ? (
								<Spinner />
							) : subscription?.is_active ? (
								"Produži pretplatu"
							) : (
								"Pretplati se"
							)}
						</Button>
					</div>
				</div>
			</div>
		</SettingsSection>
	);
}

export default BillingForm;
