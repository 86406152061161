import { useApiGet } from "@/hooks/useApi";
import { getTokenUsage } from "../api";

export function useGetTokenUsage() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["ai", "token-usage"],
		fn: getTokenUsage,
	});

	return {
		tokenUsage: data || { tokens_used: 0, token_limit: 0 },
		isPending,
		isSuccess,
		isError,
		error,
	};
}
