import { useApiSend } from "@/hooks/useApi";
import { sendMessage } from "../api";

export function useSendMessage({ unit_uuid, thread_uuid }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		invalidateKeys: [
			["ai", "threads", thread_uuid ? thread_uuid : "none"],
			["ai", "threads", "list"],
		],
		fn: ({ message }) => sendMessage({ unit_uuid, thread_uuid, message }),
	});

	return { save: mutateAsync, isPending, error, isError };
}
