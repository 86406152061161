import style from "./NotActiveSession.module.css";
import AskAiAbout from "../askAiAbout/AskAiAbout";
import Icon from "@/ui/icon/Icon";

function NotActiveSession() {
	return (
		<div className={style.notActiveSession}>
			<div className={style.aiAvatar}>
				<Icon icon="fa-user-robot" />
			</div>
			<div className={style.info}>
				<h2>Dekamas Inteligentni Asistent</h2>
				<p>
					Tu sam da Vam olakšam korištenje platforme i učenje na
					kursevima. Pitajte me šta god vam treba.
				</p>
				<AskAiAbout />
			</div>
		</div>
	);
}

export default NotActiveSession;
