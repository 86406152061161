import { useGetTokenUsage } from "../../hooks/useGetTokenUsage";
import style from "./AiCreditsCard.module.css";

import Button from "@/ui/button/Button";

function AiCreditsCard() {
	const { tokenUsage } = useGetTokenUsage();

	const remaining = Math.round(
		Math.max(0, tokenUsage.token_limit - tokenUsage.tokens_used) / 1000
	);
	const percentage = Math.min(
		100,
		tokenUsage.token_limit == 0
			? 100
			: 100 * (tokenUsage.tokens_used / tokenUsage.token_limit)
	);

	return (
		<div className={style.card}>
			<div className={style.header}>
				<span>Trenutni mjesec</span>
			</div>
			<div className={style.body}>
				<span>{remaining.toLocaleString()} kredita preostalo</span>
				<div className={style.progressHolder}>
					<div
						className={style.progress}
						style={{ width: `${percentage}%` }}
					></div>
				</div>
			</div>
		</div>
	);
}

export default AiCreditsCard;
