import { useApiGet } from "@/hooks/useApi";
import { getPaths } from "../api";

export function useGetPaths() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["paths", "list"],
		fn: getPaths,
	});

	return { paths: data || [], isPending, isSuccess, isError, error };
}
