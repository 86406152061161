import { useContext } from "react";
import style from "./PathCategories.module.css";
import PathPreviewContext from "../../contexts/PathPreviewContext";

function PathCategories({ categories }) {
	const { path } = useContext(PathPreviewContext);

	return (
		<ul className={style.categories}>
			{path.categories.map((category) => (
				<li key={category.slug}>{category.name}</li>
			))}
		</ul>
	);
}

export default PathCategories;
