import style from "./AskAiButton.module.css";

import Icon from "@/ui/icon/Icon";
import Modal from "@/ui/modal/Modal";
import AiPopup from "@/features/ai/components/aiPopup/AiPopup";

function AskAiButton({ currentUnit }) {
	return (
		<Modal>
			<Modal.Open opens="aiPopup">
				<div className={style.buttonHolder}>
					<div className={style.button}>
						<span>Pitaj AI</span>
						<Icon icon="fa-sparkles" />
					</div>
				</div>
			</Modal.Open>

			<Modal.Window name="aiPopup" maxWidth="1600px">
				<AiPopup currentUnit={currentUnit} />
			</Modal.Window>
		</Modal>
	);
}

export default AskAiButton;
