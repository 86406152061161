import style from "./PathCards.module.css";
import PathCard from "../pathCard/PathCard";
import { useGetPaths } from "../../hooks/useGetPaths";

function PathCards() {
	const { paths } = useGetPaths();

	return (
		<div className={style.holder}>
			{paths?.length > 0 &&
				paths.map((path) => <PathCard key={path.slug} path={path} />)}
		</div>
	);
}

export default PathCards;
