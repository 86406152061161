import { useApiGet } from "@/hooks/useApi";
import { getThreads } from "../api";

export function useGetThreads() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["ai", "threads", "list"],
		fn: getThreads,
	});

	return { threads: data || [], isPending, isSuccess, isError, error };
}
