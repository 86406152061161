import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Label from "./Label";
import InputError from "./InputError";
import Icon from "@/ui/icon/Icon";
import style from "./InputPassword.module.css";

function InputPassword({ name, required, label, ...props }) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className={style.holder}>
			{label && (
				<Label required={required} htmlFor={name}>
					{label}
				</Label>
			)}
			<div className={style.inputHolder}>
				<input
					className={style.input}
					id={name}
					{...props}
					{...register(name, { required })}
					type={showPassword ? "text" : "password"}
				/>
				<div
					onClick={() => {
						setShowPassword((prev) => !prev);
					}}
					className={style.icoHolder}
				>
					<Icon icon={showPassword ? "fa-eye" : "fa-eye-slash"} />
				</div>
			</div>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

export default InputPassword;
