import { useMemo, useState } from "react";

import Container from "@/layouts/container/Container";
import style from "./AllCourses.module.css";

import { useGetRecentCourses } from "@/features/courses/hooks/useGetRecentCourses";
import { Link } from "react-router-dom";
import Icon from "@/ui/icon/Icon";

import CourseFilter from "@/features/courses/components/courseSort/CourseSort";

import CourseCard from "@/features/courses/components/courseCard/CourseCard";
import TagContainer from "@/features/courses/components/coursesTagContainer/CoursesTagContainer";
import CourseTypeSelect from "@/features/courses/components/courseTypeSelect/CourseTypeSelect";

import CourseCategoryCards from "@/features/courses/components/courseCategoryCards/CourseCategoryCards";
import { useGetAllCourses } from "@/features/courses/hooks/useGetAllCourses";
import CourseSort from "@/features/courses/components/courseSort/CourseSort";

function countCategories(courses) {
	let res = {};
	for (const course of courses) {
		for (const category of course.categories) {
			if (!(category.slug in res)) {
				res[category.slug] = 0;
			}
			res[category.slug] += 1;
		}
	}
	return res;
}

function courseHasCategory(course, categorySlug) {
	return course.categories.some((category) => category.slug == categorySlug);
}

function categoriesWithCourseCounts(
	categories,
	courseCounts,
	semifilteredCourseCounts
) {
	return categories.map((category) => {
		return {
			...category,
			courseCount: courseCounts[category.slug] || 0,
			semifilteredCourseCount:
				semifilteredCourseCounts[category.slug] || 0,
			subcategories: category.subcategories.map((subcategory) => {
				return {
					...subcategory,
					courseCount: courseCounts[subcategory.slug] || 0,
					semifilteredCourseCount:
						semifilteredCourseCounts[subcategory.slug] || 0,
				};
			}),
		};
	});
}

function sortCourses(courses, sortBy) {
	return courses.toSorted((course1, course2) => {
		try {
			if (sortBy == "date-desc") {
				const d1 = Date.parse(course1.date_published);
				const d2 = Date.parse(course2.date_published);

				if (d1 > d2) return -1;
				if (d1 < d2) return 1;
				return 0;
			} else if (sortBy == "most-liked") {
				if (course1.like_count > course2.like_count) return -1;
				if (course1.like_count < course2.like_count) return 1;
				return 0;
			} else if (sortBy == "saved-first") {
				if (course1.is_saved > course2.is_saved) return -1;
				if (course1.is_saved < course2.is_saved) return 1;

				// otherwise go by save date
				if (course1.is_saved && course2.is_saved) {
					try {
						const d1 = Date.parse(course1.saved_timestamp);
						const d2 = Date.parse(course2.saved_timestamp);

						if (d1 > d2) return -1;
						if (d1 < d2) return 1;
					} catch {
						/* empty */
					}
				}

				// or go by date-desc
				const d1 = Date.parse(course1.date_published);
				const d2 = Date.parse(course2.date_published);

				if (d1 > d2) return -1;
				if (d1 < d2) return 1;
				return 0;
			}
		} catch {
			return 0;
		}
	});
}

function AllCourses() {
	const {
		courses,
		categories: rawCategories,
		isSuccess,
		isPending,
		isError,
		error,
	} = useGetAllCourses();

	const [selectedCourseType, setSelectedCourseType] = useState("all");
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedSubcategory, setSelectedSubcategory] = useState(null);
	const [selectedSortBy, setSelectedSortBy] = useState("date-desc");

	const sortedCourses = useMemo(() => {
		return sortCourses(courses, selectedSortBy);
	}, [courses, selectedSortBy]);

	const semifilteredCourses = useMemo(() => {
		return sortedCourses.filter((course) => {
			if (selectedCategory)
				if (!courseHasCategory(course, selectedCategory)) return false;

			if (selectedCourseType == "audio" && !course.is_audio) return false;
			if (selectedCourseType == "video" && course.is_audio) return false;

			return true;
		});
	}, [sortedCourses, selectedCategory, selectedCourseType]);

	const filteredCourses = useMemo(() => {
		return semifilteredCourses.filter((course) => {
			if (selectedSubcategory)
				if (!courseHasCategory(course, selectedSubcategory))
					return false;

			return true;
		});
	}, [semifilteredCourses, selectedSubcategory]);

	const categoryCourseCounts = useMemo(() => {
		return countCategories(courses);
	}, [courses]);

	const semifilteredCategoryCourseCounts = useMemo(() => {
		return countCategories(semifilteredCourses);
	}, [semifilteredCourses]);

	const categories = useMemo(() => {
		return categoriesWithCourseCounts(
			rawCategories,
			categoryCourseCounts,
			semifilteredCategoryCourseCounts
		);
	}, [categoryCourseCounts, semifilteredCategoryCourseCounts, rawCategories]);

	return (
		<section>
			<div className={style.header}>
				<div className={style.backgroundBar}>
					<Container>
						<div className={style.subHeader}>
							<div className={style.titleHolder}>
								<h2>Kursevi</h2>
								<span>({filteredCourses.length})</span>
							</div>
							<div className={style.cards}>
								<CourseCategoryCards
									categories={categories}
									selectedCategory={selectedCategory}
									setSelectedCategory={setSelectedCategory}
								/>
							</div>
						</div>
					</Container>
				</div>
				<Container>
					<div className={style.toolsBar}>
						<div className={style.filterHolder}>
							<CourseSort
								selectedSortBy={selectedSortBy}
								setSelectedSortBy={setSelectedSortBy}
							/>
						</div>
						<div className={style.tagsHolder}>
							<TagContainer
								categories={categories}
								selectedSubcategory={selectedSubcategory}
								setSelectedSubcategory={setSelectedSubcategory}
							/>
						</div>
						<div className={style.filterHolder}>
							<CourseTypeSelect
								selectedCourseType={selectedCourseType}
								setSelectedCourseType={setSelectedCourseType}
							/>
						</div>
					</div>
				</Container>
			</div>
			<div className={style.body}>
				<div className={style.coursesHolder}>
					<div className={style.grid}>
						{filteredCourses.map((course) => (
							<CourseCard key={course.slug} course={course} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
}

export default AllCourses;
