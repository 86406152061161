import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Steps
import ForgotPasswordStep from "../forgotPasswordStep/ForgotPasswordStep";

import Logo from "@/ui/logo/Logo";
import Button from "@/ui/button/Button";

import style from "./ForgotPasswordLayout.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import FormInput from "@/features/forms/components/FormInput";
import InputPassword from "@/features/forms/components/InputPassword";
import ForgotPasswordContext from "../../contexts/ForgotPasswordContext";
import { useSendForgotPasswordCode } from "../../hooks/useSendForgotPasswordCode";
import { useUseForgotPasswordCode } from "../../hooks/useUseForgotPasswordCode";
import { errorToast, warningToast } from "@/ui/toast/Toast";

function ForgotPasswordLayout({ code }) {
	const [currentStep, setCurrentStep] = useState(code ? 2 : 0);

	useEffect(() => {
		if (code) {
			setCurrentStep(2);
		}
	}, [code]);

	const steps = [
		{
			title: "Zaboravili ste lozinku?",
			description: "Email sa instrukcijama stiže!",
			icon: "fa-key-skeleton",
			actionName: "Nastavi",
		},
		{
			title: "Provjerite email",
			description:
				"Poslali smo vam email sa instrukcijama, molimo provjerite vaš inbox",
			icon: "fa-envelope",
		},
		{
			title: "Nova lozinka",
			description: "Unesite novu lozinku i potvrdite",
			icon: "fa-lock",
			actionName: "Nastavi",
		},
	];
	return (
		<ForgotPasswordContext.Provider
			value={{ currentStep, setCurrentStep, code }}
		>
			<div className={style.contentHolder}>
				<div className={style.header}>
					<Logo />
				</div>
				<div className={style.content}>
					<ForgotPasswordStep
						button={!(currentStep == 3)}
						{...steps[currentStep]}
					>
						{currentStep === 0 && <EmailRequest />}
						{currentStep === 2 && <PasswordReset />}
					</ForgotPasswordStep>
				</div>
			</div>
		</ForgotPasswordContext.Provider>
	);
}

export default ForgotPasswordLayout;

function EmailRequest({ email }) {
	const { setCurrentStep } = useContext(ForgotPasswordContext);

	const { save } = useSendForgotPasswordCode();

	const onSubmit = async (data) => {
		await save(data);
		setCurrentStep(1);
	};

	const formMethods = useFormWithValidation({
		defaultValues: {
			email: email,
		},
	});

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={formMethods.handleSubmit(onSubmit)}>
				<FormInput
					type="email"
					name="email"
					label="Email adresa"
					placeholder="Unesite email adresu"
					autoComplete="off"
					required={true}
				/>
				<Button
					type="accent"
					className={style.btn}
					disabled={formMethods.formState.isSubmitting}
				>
					Nastavi
				</Button>
			</form>
		</FormProvider>
	);
}

function PasswordReset() {
	const { code, setCurrentStep } = useContext(ForgotPasswordContext);
	const { save } = useUseForgotPasswordCode();

	const formMethods = useFormWithValidation();

	const onSubmit = async (data) => {
		try {
			await save({ code, ...data });
			setCurrentStep(3);
		} catch (e) {
			if (e?.response?.data?.errors?.code) {
				warningToast(
					"Ovaj kod za promjenu lozinke je istekao. Molimo pošaljite novi."
				);

				setCurrentStep(0);
			} else {
				throw e;
			}
		}
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={formMethods.handleSubmit(onSubmit)}>
				<InputPassword
					name="new_password1"
					placeholder="Unesite lozinku"
					label="Nova Lozinka"
					required={true}
				/>
				<InputPassword
					name="new_password2"
					placeholder="Unesite lozinku"
					label="Ponovite lozinku"
					required={true}
				/>
				<Button
					type="accent"
					className={style.btn}
					disabled={formMethods.formState.isSubmitting}
				>
					Nastavi
				</Button>
			</form>
		</FormProvider>
	);
}
