import { useState } from "react";
import style from "./IncompleteCourses.module.css";
import Icon from "@/ui/icon/Icon";

import { v4 as uuidv4 } from "uuid";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";

import IncompleteCourseCard from "../incompleteCourseCard/IncompleteCourseCard";
import { useGetIncompleteCourses } from "../../hooks/useGetIncompleteCourses";

function IncompleteCourses() {
	const [previousButtonID] = useState("button-" + uuidv4());
	const [nextButtonID] = useState("button-" + uuidv4());

	const { courses } = useGetIncompleteCourses();

	if (courses?.length == 0) return null;

	return (
		<div className={style.coursesHolder}>
			<div className={style.header}>
				<h2>Dovrši započeto</h2>
				<div className={style.navigation}>
					<div
						className={`custom-prev ${style.navButton} ${style.prev}`}
						id={previousButtonID}
					>
						<Icon icon="fa-arrow-left" />
					</div>
					<span className={style.sep}></span>
					<div
						className={`custom-next ${style.navButton} ${style.next}`}
						id={nextButtonID}
					>
						<Icon icon="fa-arrow-right" />
					</div>
				</div>
			</div>
			<Swiper
				direction="horizontal"
				slidesPerView="auto"
				pagination={{ clickable: true }}
				scrollbar={{
					draggable: true,
				}}
				lazyPreloadPrevNext={1}
				keyboard={{
					enabled: true,
					onlyInViewport: true,
				}}
				navigation={{
					nextEl: `#${nextButtonID}`,
					prevEl: `#${previousButtonID}`,
				}}
				mousewheel={true}
				modules={[Keyboard, Navigation]}
				className={style.body}
			>
				{courses.map((course) => (
					<SwiperSlide key={uuidv4()} className={style.item}>
						<IncompleteCourseCard course={course} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default IncompleteCourses;
