import { useUser } from "@/features/auth/hooks/useUser";
import { timeAgo } from "@/utils/helpers";
import React, { useState } from "react";

import Replies from "../replies/Replies";
import ReplyForm from "../replyForm/ReplyForm";
import ReplyButton from "../replyButton/ReplyButton";
import LikeCommentButton from "../likeCommentButton/LikeCommentButton";

import style from "./ParentComment.module.css";
import MoreOptions from "../moreOptions/MoreOptions";

function ParentComment({ comment, unit }) {
	const [replyingTo, setReplyingTo] = useState(null);

	const { user } = useUser();

	return (
		<li className={style.comment}>
			<div className={style.header}>
				<div className={style.userInfo}>
					<div className={style.imgHolder}>
						<img src={comment?.user?.safe_picture_url} />
					</div>
					<div className={style.otherInfo}>
						<div className={style.username}>
							{comment?.user?.username}
						</div>
						<div className={style.timestamp}>
							{timeAgo(comment.timestamp)}
						</div>
					</div>
				</div>
				{(comment?.user?.username == user?.username ||
					user?.role == "admin") && (
					<MoreOptions comment={comment} unit={unit} />
				)}
			</div>
			<div className={style.body}>
				<pre className={style.content}>{comment.content}</pre>
			</div>
			<div className={style.footer}>
				<ReplyButton
					setIsReplying={setReplyingTo}
					isReplying={replyingTo === comment.uuid}
					commentId={comment.uuid}
					replyingTo={replyingTo}
				/>
				<LikeCommentButton comment={comment} />
			</div>
			{replyingTo === comment.uuid && (
				<ReplyForm
					comment={comment}
					unit={unit}
					isReplying={true}
					setIsReplying={() => setReplyingTo(null)}
				/>
			)}

			<Replies
				comment={comment}
				user={user}
				unit={unit}
				replyingTo={replyingTo}
				setReplyingTo={setReplyingTo}
			/>
		</li>
	);
}

export default ParentComment;
