import { useState } from "react";
import style from "./LeaveReviewForm.module.css";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import Modal from "@/ui/modal/Modal";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider, useController } from "react-hook-form";
import FormTextArea from "@/features/forms/components/FormTextArea";
import InputError from "@/features/forms/components/InputError";
import { usePostCourseReview } from "../../hooks/usePostCourseReview";
import { successToast } from "@/ui/toast/Toast";

function LeaveReviewForm({ onCloseModal, course }) {
	const [hoverRating, setHoverRating] = useState(0);
	const formMethods = useFormWithValidation();

	const {
		field: starField,
		fieldState: { starFieldError },
	} = useController({ name: "stars", control: formMethods.control });

	const { save } = usePostCourseReview({ course });

	const onSubmit = async (data) => {
		await save(data);
		onCloseModal();
		successToast("Uspješno ste ostavili recenziju!");
	};

	return (
		<FormProvider {...formMethods}>
			<form
				className={style.reviewForm}
				onSubmit={formMethods.handleSubmit(onSubmit)}
			>
				<div className={style.close}>
					<Modal.Close />
				</div>

				<div className={style.header}>
					<h3>{course?.name}</h3>
					<p>Molim vas izdvojite momenat da ostavite recenziju</p>
				</div>
				<div className={style.body}>
					<div className={style.starsHolder}>
						{[1, 2, 3, 4, 5].map((star) => (
							<span
								key={star}
								className={`${style.star} ${
									star <= (hoverRating || starField.value)
										? style.filled
										: ""
								}`}
								onClick={() => starField.onChange(star)}
								onMouseEnter={() => setHoverRating(star)}
								onMouseLeave={() => setHoverRating(0)}
							>
								<Icon icon="fa-star" />
							</span>
						))}

						{starFieldError && (
							<InputError>{starFieldError?.message}</InputError>
						)}
					</div>
					<FormTextArea
						name="content"
						placeholder="Vaša recenzija..."
					/>
				</div>
				<div className={style.footer}>
					<Button
						type="accent"
						className={style.btn}
						disabled={
							!starField.value ||
							formMethods?.formState?.isSubmitting
						}
					>
						Submit
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}

export default LeaveReviewForm;
