import { useState, useEffect } from "react";

import style from "./RegisterLayout.module.css";

import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import { successToast } from "@/ui/toast/Toast";

import RegisterSidebar from "@/features/auth/components/registerSidebar/RegisterSidebar";
import RegisterEmailStep from "../registerEmailStep/RegisterEmailStep";
import RegisterDetailsStep from "../registerDetailsStep/RegisterDetailsStep";
import RegisterSettingsStep from "../registerSettingsStep/RegisterSettingsStep";
import RegisterOTPStep from "../registerOTPStep/RegisterOTPStep";
import ChoosePlan from "@/features/auth/components/choosePlan/ChoosePlan";

function RegisterLayout({ isPlansPage = false }) {
	const [currentStep, setCurrentStep] = useState(null);
	const [email, setEmail] = useState("");
	const [code, setCode] = useState(null);
	const { isLoading, isLoggedIn, user } = useUser();

	const navigate = useNavigate();

	// Load step 1 (email entry) if not logged in, step 3 (details) if already logged in
	// for example if they logged in with Google
	useEffect(() => {
		if (isLoading || currentStep !== null) return;
		if (isLoggedIn) {
			if (isPlansPage) setCurrentStep(4);
			else setCurrentStep(3);
		} else {
			setCurrentStep(1);
			if (isPlansPage) navigate("/register", { replace: true });
		}
	}, [isLoading, isLoggedIn, isPlansPage]);

	if (isLoading) return <FullPageLoader />;

	return (
		<div className={style.grid}>
			<RegisterSidebar stepIndex={currentStep} />
			<div className={style.contentHolder}>
				<div className={style.content}>
					{currentStep == 1 && (
						<RegisterEmailStep
							email={email}
							setEmail={setEmail}
							nextStep={() => {
								setCurrentStep(2);
							}}
						/>
					)}
					{currentStep == 2 && (
						<RegisterOTPStep
							email={email}
							code={code}
							setCode={setCode}
							prevStep={() => {
								setCurrentStep(1);
							}}
							nextStep={() => {
								setCurrentStep(3);
							}}
						/>
					)}
					{currentStep == 3 &&
						(isLoggedIn ? (
							<RegisterSettingsStep
								user={user}
								nextStep={() => {
									setCurrentStep(4);
									navigate("/plans");
								}}
							/>
						) : (
							<RegisterDetailsStep
								code={code}
								email={email}
								prevStep={() => {
									setCurrentStep(2);
								}}
								nextStep={() => {
									setCurrentStep(4);
									navigate("/plans");
								}}
								emailStep={() => {
									setCurrentStep(1);
								}}
							/>
						))}
					{currentStep == 4 && <ChoosePlan />}
				</div>
			</div>
		</div>
	);
}

export default RegisterLayout;
