import { useUser } from "@/features/auth/hooks/useUser";
import style from "./ReviewCard.module.css";

import Icon from "@/ui/icon/Icon";
import { timeAgo } from "@/utils/helpers";
import MoreOptions from "../moreOptions/MoreOptions";

function ReviewCard({ review, course }) {
	const { user } = useUser();

	const imgUrl = review?.user?.safe_picture_url;
	const name = review?.user?.username;
	const date = timeAgo(new Date(review?.timestamp).getTime());
	const rating = review?.stars;
	const text = review?.content;
	const canDelete = review?.user?.username == user?.username;

	return (
		<div className={style.review}>
			<div className={style.header}>
				<div className={style.user}>
					<div className={style.imgHolder}>
						<img src={imgUrl} alt={`${name}'s avatar`} />
					</div>
					<div className={style.userInfo}>
						<div className={style.name}>{name}</div>
						<div className={style.date}>{date}</div>
					</div>
				</div>
				{canDelete && <MoreOptions course={course} />}
			</div>
			<div className={style.body}>
				<div className={style.starsHolder}>
					<div
						className={style.stars}
						style={{ "--rating": rating }}
					></div>
					<div>
						Ocijenio/la sa ocjenom <span>{rating}</span>
					</div>
				</div>
				<div className={style.text}>{text}</div>
			</div>
		</div>
	);
}

function NoReviewCard() {
	return (
		<div className={style.noRatingHolder}>
			<div className={style.reviewHolder}>
				<div className={style.background}></div>
				<div className={style.review}>
					<div className={style.header}>
						<div className={style.user}>
							<div className={style.imgHolder}></div>
							<div className={style.userInfo}>
								<div className={style.name}></div>
								<div className={style.date}></div>
							</div>
						</div>
					</div>
					<div className={style.body}>
						<div className={style.starsHolder}>
							<div className={style.stars}></div>
							<div className={style.text}>
								<span></span>
								<span></span>
							</div>
						</div>
						<div className={style.text}></div>
					</div>
				</div>
			</div>
			<div className={style.noReviewText}>
				<h3>Trenutno nema recenzija</h3>
				<p>Budite prvi koji će ostaviti recenziju</p>
			</div>
		</div>
	);
}

export { NoReviewCard };
export default ReviewCard;
