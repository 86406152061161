import { useRef, useState, useEffect, useCallback, useContext } from "react";

import style from "./AiPostQuestionForm.module.css";

import { FormProvider } from "react-hook-form";

import FormTextArea from "@/features/forms/components/FormTextArea";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import AIPopupContext from "../../contexts/AIPopupContext";
import { useSendMessage } from "../../hooks/useSendMessage";

function AiPostQuestionForm({ inSession }) {
	const {
		currentThread,
		setCurrentThread,
		currentUnit,
		runToRefetch,
		setRunToRefetch,
		prefilledAnswer,
		setPrefilledAnswer,
	} = useContext(AIPopupContext);

	const MAX_CONTENT_LENGTH = 1000;
	const formMethods = useFormWithValidation({
		defaultValues: {
			message: "",
		},
	});

	const {
		handleSubmit,
		setError,
		setValue,
		reset,
		watch,
		formState: { isSubmitting, errors },
	} = formMethods;

	const message = watch("message");
	const messageRef = useRef(message);

	const { save } = useSendMessage({
		unit_uuid: currentUnit?.video?.uuid,
		thread_uuid: currentThread,
	});

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		console.log(data);
		const response = await save({ message: data.message });
		setValue("message", "");
		if (response?.thread_uuid) setCurrentThread(response?.thread_uuid);
		if (response?.uuid) setRunToRefetch(response?.uuid);
	};

	const handleKeyDown = function (event) {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			handleSubmit(onSubmit)();
		}
	};

	const handlePaste = useCallback(
		(event) => {
			const pastedData = event.clipboardData.getData("text");
			const maxLength = MAX_CONTENT_LENGTH;
			const currentContent = messageRef.current || "";
			if (currentContent.length + pastedData.length > maxLength) {
				event.preventDefault();
				const allowedText = pastedData.substring(
					0,
					maxLength - currentContent.length
				);
				setValue("message", currentContent + allowedText);
				setError("message", {
					type: "maxLength",
					message: `Maximum length of ${MAX_CONTENT_LENGTH} characters exceeded.`,
				});
			}
		},
		[setValue, setError, MAX_CONTENT_LENGTH]
	);

	const handleChange = useCallback(
		(e) => {
			const newValue = e.target.value;
			if (newValue.length <= MAX_CONTENT_LENGTH) {
				setValue("message", newValue);
				if (errors.content?.type === "maxLength") {
					setError("message", { ...errors.content, message: "" });
				}
			} else {
				setValue("message", newValue.substring(0, MAX_CONTENT_LENGTH));
				setError("message", {
					type: "maxLength",
					message: `Maximum length of ${MAX_CONTENT_LENGTH} characters exceeded.`,
				});
			}
		},
		[setValue, setError, MAX_CONTENT_LENGTH, errors.message]
	);

	useEffect(() => {
		if (prefilledAnswer) {
			setValue("message", prefilledAnswer);
			handleSubmit(onSubmit)();
			setPrefilledAnswer(null);
		}
	}, [prefilledAnswer]);

	return (
		<FormProvider {...formMethods}>
			<form
				className={`${style.postForm} ${
					message.length > 0 && message.length < MAX_CONTENT_LENGTH
						? style.active
						: ""
				} ${inSession ? style.inSession : ""}`}
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<FormTextArea
					name="message"
					rows={3}
					required
					placeholder="Postavi neko pitanje..."
					className={style.input}
					onKeyDown={handleKeyDown}
					showErrors={false}
					onPaste={handlePaste}
					onChange={handleChange}
					aria-invalid={errors.message ? "true" : "false"}
					aria-describedby={
						errors.message ? "content-error" : undefined
					}
				/>

				{errors.message && (
					<span id="message-error" className={style.error}>
						{errors.message.message}
					</span>
				)}
				<div className={style.footer}>
					<div className={style.icoHolder}>
						<Icon icon="fa-wand-magic-sparkles" />
					</div>
					<div className={style.msgLength}>
						<span
							className={
								message.length > MAX_CONTENT_LENGTH
									? style.limit
									: ""
							}
						>
							{message.length}
						</span>
						<span>/</span>
						<span>{MAX_CONTENT_LENGTH}</span>
					</div>
					<div className={style.btnsHolder}>
						<Button
							className={style.sendBtn}
							disabled={
								isSubmitting ||
								message.length == 0 ||
								message.length > MAX_CONTENT_LENGTH
							}
							type="accent"
							aria-disabled={
								isSubmitting ||
								message.length == 0 ||
								message.length > MAX_CONTENT_LENGTH
							}
						>
							{isSubmitting ? (
								"..."
							) : (
								<span>
									<Icon icon="fa-paper-plane-top" />
								</span>
							)}
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
}

export default AiPostQuestionForm;
