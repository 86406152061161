import style from "./ReviewSection.module.css";
import Modal from "@/ui/modal/Modal";

import LeaveReviewForm from "./components/leaveReviewForm/LeaveReviewForm";
import Review, { NoReviewCard } from "./components/reviewCard/ReviewCard";
import ReviewsOverview from "./components/reviewsOverview/ReviewsOverview";
import ReviewsFilter from "./components/reviewsFilter/ReviewsFilter";
import { useCourseReviews } from "./hooks/useCourseReviews";
import { timeAgo } from "@/utils/helpers";
import { useUser } from "../auth/hooks/useUser";
import { useMemo, useState } from "react";

// MostLikedVideos Component (Optional)
function MostLikedVideos() {
	return (
		<div className={style.mostLikedVideos}>
			<h4>Most liked</h4>
			<div className={style.likedVideos}></div>
		</div>
	);
}

function ReviewSection({ course }) {
	const [selectedReviewSort, setSelectedReviewSort] = useState("most-recent");
	const { reviews, isPending, error } = useCourseReviews({ course });
	const { user } = useUser();

	const sortedReviews = useMemo(() => {
		if (!reviews) return [];

		return reviews.toSorted((review1, review2) => {
			try {
				if (selectedReviewSort == "most-recent") {
					const d1 = Date.parse(review1.created_at);
					const d2 = Date.parse(review2.date_published);

					if (d1 > d2) return -1;
					if (d1 < d2) return 1;
					return 0;
				} else if (selectedReviewSort == "lowest-rated") {
					if (review1?.stars > review2?.stars) return 1;
					if (review1?.stars < review2?.stars) return -1;
					return 0;
				} else {
					if (review1?.stars > review2?.stars) return -1;
					if (review1?.stars < review2?.stars) return 1;
					return 0;
				}
			} catch (e) {
				return 0;
			}
		});
	}, [reviews, selectedReviewSort]);

	if (isPending) return <>Učitavanje...</>;

	return (
		<Modal>
			<section className={style.reviewSection}>
				<div className={style.header}>
					<h2>Recenzije kursa</h2>
					{reviews.length != 0 && (
						<div className={style.filterHolder}>
							<ReviewsFilter
								selectedReviewSort={selectedReviewSort}
								setSelectedReviewSort={setSelectedReviewSort}
							/>
						</div>
					)}
				</div>
				<div className={style.body}>
					<div className={style.main}>
						<ReviewsOverview reviews={reviews} />
						{/* <MostLikedVideos /> */}
					</div>
					<div className={style.reviewsHolder}>
						{reviews.length == 0 && <NoReviewCard />}
						{sortedReviews.map((r) => (
							<Review key={r.uuid} review={r} course={course} />
						))}
					</div>
				</div>
			</section>

			<Modal.Window name="leaveReview" maxWidth="450px">
				<LeaveReviewForm course={course} />
			</Modal.Window>
		</Modal>
	);
}

export default ReviewSection;
