import style from "./VideoBreadcrumb.module.css";
import Icon from "@/ui/icon/Icon";

function VideoBreadcrumb({ video }) {
	// course -> module -> lesson/ quiz
	return (
		<div className={style.breadcrumb}>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-chalkboard" />
				</div>
				<span>{video.module.course.name}</span>
				<div className={style.separator}>
					<Icon icon="fa-chevron-right" />
				</div>
			</div>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-layer-group" />
				</div>
				<span>{video.module.name}</span>
				<div className={style.separator}>
					<Icon icon="fa-chevron-right" />
				</div>
			</div>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-film" />
				</div>
				<span>{video.name}</span>
			</div>
		</div>
	);
}

export default VideoBreadcrumb;
