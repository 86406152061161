import { useEffect, useReducer, useRef } from "react";
import Loader from "@/ui/loader/Loader";
import Question from "./components/question/Question";

import Progress from "./components/progress/Progress";
import FinishedScreen from "./components/finishedScreen/FinishedScreen";

import correctAnswer from "@/assets/sounds/correct_answer.mp3";
import wrongAnswer from "@/assets/sounds/bad_answer.mp3";
import levelComplete from "@/assets/sounds/level_complete.mp3";

// import AnswerRespond from "./AnswerRespond";
import AnswerRespond from "./components/answerRespond/AnswerRespond";

import Container from "@/layouts/container/Container";

import style from "./Quiz.module.css";
import CheckButton from "./components/checkButton/CheckButton";
import SkipButton from "./components/skipButton/SkipButton";

import ShortcutKey from "@/ui/shortcutKey/ShortcutKey";
import { Icon } from "lucide-react";

function reducer(state, action) {
	switch (action.type) {
		case "start":
			return {
				...state,
				status: "active",
			};
		case "newAnswer":
			return {
				...state,
				isCorrectAnswer: action.payload.isCorrect,
			};
		case "answerSelected":
			return {
				...state,
				answer: action.payload,
			};
		case "checkAnswer":
			return {
				...state,
				isChecked: true,
				points: action.payload.isCorrect
					? state.points + 1
					: state.points,
				isCorrectAnswer: action.payload.isCorrect,
				hasAnswered: true,
			};
		case "nextQuestion":
			return {
				...state,
				index: state.index + 1,
				answer: null,
				isCorrectAnswer: false,
				isChecked: false,
				hasAnswered: false,
			};
		case "finish":
			return {
				...state,
				status: "finished",
				index: state.index + 1,
				answer: null,
				isCorrectAnswer: false,
				isChecked: false,
				hasAnswered: false,
				highscore:
					state.points > state.highscore
						? state.points
						: state.highscore,
			};
		case "restart":
			return {
				questions: state.questions,
				status: "active",
				index: 0,
				answer: null,
				points: 0,
				highscore: state.highscore,
				isCorrectAnswer: false,
				isChecked: false,
			};
		default:
			throw new Error("Action is unknown");
	}
}

function Quiz({ quiz }) {
	const [
		{
			questions,
			status,
			index,
			answer,
			points,
			isCorrectAnswer,
			isChecked,
			hasAnswered,
		},
		dispatch,
	] = useReducer(reducer, {
		questions: quiz.questions,
		status: "active",
		index: 0,
		answer: null,
		points: 0,
		isCorrectAnswer: false,
		isChecked: false,
		hasAnswered: false,
	});

	const maxPossiblePoints = questions.length;

	const correctAnswerAudio = useRef(new Audio(correctAnswer));
	const wrongAnswerAudio = useRef(new Audio(wrongAnswer));
	const levelCompleteAudio = useRef(new Audio(levelComplete));

	const playAudio = (audioRef) => {
		audioRef.current.pause();
		audioRef.current.currentTime = 0;
		audioRef.current.play().catch(() => {
			audioRef.current.addEventListener(
				"canplaythrough",
				() => {
					audioRef.current.play();
				},
				{ once: true }
			);
		});
	};

	useEffect(() => {
		if (isChecked) {
			if (isCorrectAnswer) {
				playAudio(correctAnswerAudio);
			} else {
				playAudio(wrongAnswerAudio);
			}
		}
	}, [isChecked, isCorrectAnswer]);

	useEffect(() => {
		if (status === "finished") {
			playAudio(levelCompleteAudio);
		}
	}, [status]);

	return (
		<div className={style.quiz}>
			{status === "loading" && <Loader />}
			{status === "active" && (
				<>
					<div className={style.header}>
						<Container size="medium">
							<Progress
								index={index}
								numberOfQuestions={questions.length}
								points={points}
							/>
						</Container>
					</div>
					<div className={style.main}>
						<Container size="medium">
							{questions.length > index && (
								<Question
									dispatch={dispatch}
									answer={answer}
									question={questions[index]}
									hasAnswered={hasAnswered}
								/>
							)}
						</Container>
					</div>
				</>
			)}
			{status === "finished" && (
				<div className={style.main}>
					<FinishedScreen
						points={points}
						maxPoints={maxPossiblePoints}
					/>
				</div>
			)}

			<div className={style.footer}>
				{isChecked ? (
					<AnswerRespond
						dispatch={dispatch}
						isCorrectAnswer={isCorrectAnswer}
						index={index}
						numQuestions={questions.length}
						questions={questions}
						quiz={quiz}
						answer={answer}
						correctAnswer={
							quiz.questions[
								Math.min(index, quiz.questions.length - 1)
							].answers.find((a) => a.isCorrect).answer
						}
					/>
				) : (
					<Container size="medium">
						<SkipButton
							dispatch={dispatch}
							index={index}
							questions={questions}
						/>
						<CheckButton
							index={index}
							questions={questions}
							dispatch={dispatch}
							answer={answer}
						/>
					</Container>
				)}
			</div>

			<div className={style.controls}>
				<Container size="medium">
					<ShortcutKey
						symbols={["a", "b", "c", "d"]}
						name="navigacija"
					/>
					<ShortcutKey symbols={["return"]} name="provjeri" />
				</Container>
			</div>
		</div>
	);
}

export default Quiz;
