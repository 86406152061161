import React, { useState } from "react";
import style from "./PathTestTimePicker.module.css";

const times = [
	"8:00",
	"9:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
];

function PathTestTimePicker() {
	const [selectedTime, setSelectedTime] = useState(null);

	const handleTimeChange = (event) => {
		setSelectedTime(event.target.value);
	};

	return (
		<form className={style.holder}>
			{times.map((time, index) => (
				<div
					key={index}
					className={`${style.time} ${
						selectedTime === time ? style.active : ""
					}`}
				>
					<input
						type="radio"
						name="time"
						id={`time${index}`}
						value={time}
						checked={selectedTime === time}
						onChange={handleTimeChange}
					/>
					<label htmlFor={`time${index}`}>{time}h</label>
				</div>
			))}
		</form>
	);
}

export default PathTestTimePicker;
