import style from "./ChangePasswordForm.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import { successToast } from "@/ui/toast/Toast";
import Button from "@/ui/button/Button";

import Icon from "@/ui/icon/Icon";

import SettingSection from "../settingsSection/SettingsSection";

import InputPassword from "@/features/forms/components/InputPassword";
import { useChangePassword } from "@/features/auth/hooks/useChangePassword";

export default function ChangePasswordForm({ user }) {
	const formMethods = useFormWithValidation();

	const {
		handleSubmit,
		setValue,
		formState: { isSubmitting },
	} = formMethods;

	const { save } = useChangePassword();

	const onSubmit = async (data) => {
		await save(data);
		setValue("new_password1", "");
		setValue("new_password2", "");
		successToast("Uspjesno spasena nova lozinka");
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SettingSection
					title="Promijenite lozinku"
					description="Uredite svoju lozinku kako Vi želite"
				>
					<InputPassword
						name="new_password1"
						placeholder="Unesite lozinku"
						label="Nova lozinka"
						required={true}
					/>
					<InputPassword
						name="new_password2"
						placeholder="Unesite lozinku"
						label="Ponovite lozinku"
						required={true}
					/>
				</SettingSection>

				<div className={style.btnHolder}>
					<Button
						className={style.btn}
						type="accent"
						disabled={isSubmitting}
					>
						<Icon icon="fa-floppy-disk" />
						{isSubmitting ? <Icon icon="fa-ellipsis" /> : "Sačuvaj"}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}
