import { useApiGet } from "@/hooks/useApi";
import { getPath } from "../api";

export function useGetPath({ pathSlug }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["paths", pathSlug],
		fn: () => getPath({ pathSlug }),
	});

	return { path: data || null, isPending, isSuccess, isError, error };
}
