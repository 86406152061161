import style from "./PathCard.module.css";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import { secondsToHoursMinutes } from "@/utils/helpers";

function PathCard({ path }) {
	return (
		<div className={style.card}>
			<div
				className={style.thumbnailHolder}
				style={{
					"--thumbnail": `url(${path.thumbnail})`,
				}}
			></div>
			<div className={style.info}>
				<div className={style.title}>
					<h3>{path.name}</h3>
				</div>
				<div className={style.details}>
					<div className={style.detail}>
						<div className={style.labelHolder}>
							<div className={style.icoHolder}>
								<Icon icon="fa-chalkboard" />
							</div>
							<div className={style.label}>Broj kurseva</div>
						</div>
						<div className={style.valueHolder}>
							<div className={style.value}>
								{path.course_count}
							</div>
						</div>
					</div>

					<div className={style.detail}>
						<div className={style.labelHolder}>
							<div className={style.icoHolder}>
								<Icon icon="fa-layer-group" />
							</div>
							<div className={style.label}>Broj cjelina</div>
						</div>
						<div className={style.valueHolder}>
							<div className={style.value}>
								{path.module_count}
							</div>
						</div>
					</div>
					<div className={style.detail}>
						<div className={style.labelHolder}>
							<div className={style.icoHolder}>
								<Icon icon="fa-user" />
							</div>
							<div className={style.label}>Broj predavača</div>
						</div>
						<div className={style.valueHolder}>
							<div className={style.value}>
								{path.lecturers.length}
							</div>
							<div className={style.teachers}>
								{/* // show max for and add */}
								{path.lecturers.slice(0, 4).map((lecturer) => (
									<img
										key={lecturer.name}
										src={lecturer.picture_url}
									></img>
								))}
								{path.lecturers.length > 4 && (
									<span>+{path.lecturers.length - 4}</span>
								)}
							</div>
						</div>
					</div>
					<div className={style.detail}>
						<div className={style.labelHolder}>
							<div className={style.icoHolder}>
								<Icon icon="fa-clock" />
							</div>
							<div className={style.label}>Trajanje</div>
						</div>
						<div className={style.valueHolder}>
							<div className={style.value}>
								{secondsToHoursMinutes(path.duration)}
							</div>
						</div>
					</div>
				</div>
				<div className={style.footer}>
					<div className={style.pointsHolder}>
						<div className={style.points}>
							<span className={style.value}>
								{path.ects_credits}
							</span>
							<span className={style.valute}>
								<span>ECTS</span>
								<span>bodova</span>
							</span>
						</div>
					</div>
					<Button
						className={style.btn}
						to={`/paths/${path.slug}`}
						type="border"
					>
						<span>Saznaj više</span>
						<Icon icon="fa-angles-right" iconType="fa-solid" />
					</Button>
				</div>
			</div>
		</div>
	);
}

export default PathCard;
