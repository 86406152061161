import { useState } from "react";

import style from "./PathScheduleTestPopup.module.css";
import Modal from "@/ui/modal/Modal";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

import Calendar from "@/ui/calendar/Calendar";
import PathTestTimePicker from "../pathTestTimePIcker/PathTestTimePicker";

function PathScheduleTestPopup() {
	const [step, setStep] = useState(0);
	const [date, setDate] = useState(new Date());

	const handleNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	return (
		<div className={style.container}>
			<div className={style.header}>
				<div className={style.currentStep}>
					<div className={style.icoHolder}>
						{step === 0 && <Icon icon="fa-calendar" />}
						{step === 1 && <Icon icon="fa-check" />}
					</div>
					<span>Zakažite testiranje</span>
				</div>
				<div className={style.closeButton}>
					<Modal.Close />
				</div>
			</div>
			<div className={style.body}>
				{step === 0 && (
					<>
						<div>
							<Calendar
								mode="single"
								selected={date}
								onSelect={setDate}
							/>
						</div>
						<div className={style.chooseTime}>
							<PathTestTimePicker />
						</div>
					</>
				)}
				{step === 1 && (
					<div className={style.success}>
						<div className={style.successIcon}>
							<Icon icon="fa-check" />
						</div>
						<h2>Uspešno ste zakazali testiranje</h2>
						<p>
							Uskoro ćete dobiti potvrdu o zakazanom terminu na
							vasu email adresu
						</p>
					</div>
				)}
			</div>
			<div className={style.footer}>
				{step < 1 ? (
					<Button
						className={style.btn}
						type="accent"
						onClick={handleNextStep}
					>
						Dalje
					</Button>
				) : null}
			</div>
		</div>
	);
}

export default PathScheduleTestPopup;
