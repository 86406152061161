import { useContext, useState } from "react";
import style from "./AskAiAbout.module.css";
import AIPopupContext from "../../contexts/AIPopupContext";

function AskAiAbout() {
	const { setPrefilledAnswer } = useContext(AIPopupContext);

	const example_questions = [
		"O čemu se radi u ovom kursu?",
		"Napravi mi sažetak ovog videa",
		"Daj mi primjere iz stvarnog života za ovo gradivo",
		"Postavi mi par pitanja iz lekcije",
		"Predloži mi dodatne resurse za ovu temu",
	];

	return (
		<div className={style.askAbout}>
			<h3>Pitaj me:</h3>
			<ul>
				{example_questions.map((item, index) => (
					<li key={index} onClick={() => setPrefilledAnswer(item)}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
}

export default AskAiAbout;
