import React, { forwardRef } from "react";
import Icon from "@/ui/icon/Icon";
import style from "./SearchInput.module.css";
import { Command } from "cmdk";
import ShortcutKey from "@/ui/shortcutKey/ShortcutKey";

const SearchInput = forwardRef(({ value, onValueChange, onFocus, onBlur }, ref) => {
    return (
        <div className={style.searchHolder}>
            <div className={style.icoHolder}>
                <Icon icon="fa-magnifying-glass" />
            </div>
            <Command.Input
                value={value}
                onValueChange={onValueChange}
                onFocus={onFocus}
                placeholder="Pretraži..."
                onBlur={onBlur}
                ref={ref}
            />
            <div className={style.shortcut}>
                <ShortcutKey symbols={["command", "k"]} />
            </div>
        </div>
    );
});

export default SearchInput;