import style from "./SettingsForm.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { useEditSettings } from "../../hooks/useEditSettings";

import { FormProvider, useFormContext } from "react-hook-form";
import FormInput from "@/features/forms/components/FormInput";
import FormImage from "@/features/forms/components/FormImage";
import InputPhone from "@/features/forms/components/InputPhone";

import SettingsSection from "../settingsSection/SettingsSection";

import { successToast } from "@/ui/toast/Toast";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

export default function SettingsForm({ user }) {
	const formMethods = useFormWithValidation({
		values: {
			username: user.username,
			email: user.email,
			first_name: user.firstName,
			last_name: user.lastName,
			phone_number: user.phoneNumber || "",
			picture: user.picture,
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
		watch,
	} = formMethods;

	const { save } = useEditSettings();

	const onSubmit = async (data) => {
		await save(data);
		successToast("Uspjesno spašene postavke");
	};

	const watchedValues = watch();

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SettingsSection
					title="O vama"
					description="Pregled Vaših informacija"
				>
					<div
						className={style.userCard}
						style={{
							"--background": `url(${watchedValues.picture})`,
						}}
					>
						<div className={style.imgHolder}>
							<FormImage
								initialPreviewImageURL={user.picture}
								name="picture"
							/>
						</div>
						<div className={style.info}>
							<span className={style.username}>
								@{watchedValues.username}
							</span>
							<span className={style.fullName}>
								{watchedValues.first_name}{" "}
								{watchedValues.last_name}
							</span>
							<span className={style.phoneNumber}>
								<Icon icon="fa-phone" />
								<span>{watchedValues.phone_number}</span>
							</span>
						</div>
					</div>
				</SettingsSection>

				<SettingsSection
					title="Email adresa"
					description="Ova vrijednost se ne može mijenjati"
				>
					<FormInput notAllowed name="email" label="Email" />
				</SettingsSection>

				<SettingsSection
					title="Vaše lične informacije"
					description="Ovdje možete urediti Vaše lične infomracije"
				>
					<FormInput name="username" label="Korisničko ime" />
					<FormInput name="first_name" label="Ime" />
					<FormInput name="last_name" label="Prezime" />
					<InputPhone name="phone_number" label="Broj telefona" />
				</SettingsSection>

				<div className={style.btnHolder}>
					<Button
						className={style.btn}
						type="accent"
						disabled={isSubmitting}
					>
						<Icon icon="fa-floppy-disk" />
						{isSubmitting ? <Icon icon="fa-ellipsis" /> : "Sačuvaj"}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}
