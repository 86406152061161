import { useApiGet } from "@/hooks/useApi";
import { getRuns } from "../api";

export function useGetRuns({ thread_uuid }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["ai", "threads", thread_uuid ? thread_uuid : "none"],
		fn: thread_uuid ? () => getRuns({ thread_uuid }) : () => [],
	});

	return { runs: data || [], isPending, isSuccess, isError, error };
}
