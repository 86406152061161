import { useLoginWithGoogle } from "../hooks/useLoginWithGoogle";
import style from "./GoogleButton.module.css";

export default function GoogleButton() {
	const { loginWithGoogle } = useLoginWithGoogle();
	return (
		<button
			type="button"
			onClick={loginWithGoogle}
			className={style.googleButton}
		>
			<svg width="20" height="21" viewBox="0 0 20 21" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.6 10.225C19.6 9.51598 19.5364 8.8342 19.4182 8.17969H10V12.0477H15.3818C15.15 13.2976 14.4455 14.3566 13.3864 15.0657V17.5747H16.6182C18.5091 15.8338 19.6 13.2703 19.6 10.225Z"
					fill="#4285F4"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.99984 20.0019C12.6998 20.0019 14.9635 19.1065 16.618 17.5793L13.3862 15.0704C12.4907 15.6703 11.3453 16.0249 9.99984 16.0249C7.39529 16.0249 5.19075 14.2659 4.40439 11.9023H1.06348V14.4931C2.70893 17.7611 6.09075 20.0019 9.99984 20.0019Z"
					fill="#34A853"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.40455 11.8984C4.20455 11.2984 4.09091 10.6575 4.09091 9.99849C4.09091 9.33943 4.20455 8.69856 4.40455 8.09859V5.50781H1.06364C0.386364 6.85774 0 8.38494 0 9.99849C0 11.612 0.386364 13.1392 1.06364 14.4892L4.40455 11.8984Z"
					fill="#FBBC05"
				></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.99984 3.97707C11.468 3.97707 12.7862 4.48158 13.8226 5.47244L16.6907 2.60441C14.9589 0.990857 12.6953 0 9.99984 0C6.09075 0 2.70893 2.24079 1.06348 5.5088L4.40439 8.09958C5.19075 5.73606 7.39529 3.97707 9.99984 3.97707Z"
					fill="#EA4335"
				></path>
			</svg>
			Prijavite se preko Google-a
		</button>
	);
}
