import style from "./LoginForm.module.css";
import { useLogin } from "../hooks/useLogin";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import GoogleButton from "./GoogleButton";

import InputPassword from "@/features/forms/components/InputPassword";
import FormInput from "@/features/forms/components/FormInput";
import { FormProvider, useForm } from "react-hook-form";

import Spinner from "@/features/forms/components/Spinner";

import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";

import { Link } from "react-router-dom";

export function LoginForm() {
	const { login } = useLogin();

	const formMethods = useFormWithValidation();
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	return (
		<div className={style.holder}>
			<div className={style.subheader}>
				<h2>Prijavite se</h2>
			</div>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(login)} className={style.form}>
					<FormInput
						name="username"
						label="Korisničko ime / Email"
						placeholder="Unesite korisničko ime"
					/>
					<InputPassword
						name="password"
						label="Lozinka"
						placeholder="Unesite lozinku"
						type="password"
					/>
					<Link
						to="/forgot-password"
						className={style.forgetPassword}
					>
						Zaboravili ste lozinku?
					</Link>
					<Button type="accent" className={style.btn}>
						{isSubmitting ? (
							<Spinner />
						) : (
							<>
								<span>Prijavite se</span>{" "}
								<Icon icon="fa-arrow-right" />
							</>
						)}
					</Button>
				</form>
			</FormProvider>
			<div className={style.orSeparator}>
				<span className={style.line}></span>
				<p>ili</p>
				<span className={style.line}></span>
			</div>
			<GoogleButton />
		</div>
	);
}
