import style from "./RegisterEmailStep.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSendVerificationCode } from "../../hooks/useSendVerificationCode";

import GoogleButton from "../GoogleButton";

import FormInput from "@/features/forms/components/FormInput";
import Spinner from "@/features/forms/components/Spinner";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

function RegisterEmailStep({ email, setEmail, nextStep }) {
	const formMethods = useFormWithValidation({
		defaultValues: {
			email: email,
		},
	});

	const { sendCode } = useSendVerificationCode();
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;
	const navigate = useNavigate();

	const onSubmit = async function (data) {
		await sendCode(data);
		setEmail(data.email);
		nextStep();
	};

	return (
		<div className={style.container}>
			<div className={style.header}>
				<Icon icon="fa-at" />
				<h2>Unesite svoju email adresu</h2>
			</div>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormInput
						type="email"
						name="email"
						label="Email adresa"
						placeholder="Unesite email adresu"
						autoComplete="off"
						required={true}
					/>
					<div className={style.buttonHolder}>
						<Button type="accent">
							{isSubmitting ? <Spinner /> : "Nastavi"}
						</Button>
					</div>

					<div className={style.sepHolder}>
						<span className={style.line}></span>
						<span>ili</span>
						<span className={style.line}></span>
					</div>
					<GoogleButton />
				</form>
			</FormProvider>
		</div>
	);
}

export default RegisterEmailStep;
