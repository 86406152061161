import style from "./PathObtainCertificate.module.css";
import Certificate from "@/ui/certificate/Certificate";

function PathObtainCertificate() {
	return (
		<div className={style.holder}>
			<div className={style.info}>
				<h3>Potvrdite znanje certifikatom.</h3>
				<p>
					Nakon završetka kursa i projekata, možete aplicirati za
					mikroakreditiv na univerzitetu i dobiti akreditovanu
					diplomu.
				</p>
			</div>
			<div className={style.certificateHolder}>
				<Certificate className={style.certificate} size={18} />
			</div>
		</div>
	);
}

export default PathObtainCertificate;
