export function mapDjangoValidationErrors({ error, setError }) {
	if (error?.response?.data?.errors) {
		const formErrors = error.response.data.errors;

		// prettier-ignore
		for (const [fieldName, fieldErrors] of Object.entries(formErrors)) {
      for (const fieldError of fieldErrors) {
        
		    console.log("Setting the error:", fieldError);
        setError(fieldName, {
          type: "server",
          message: fieldError.message,
        });
      }
    }
	}
}
