import { useApiGet } from "@/hooks/useApi";
import { getIncompleteCourses } from "../api";

export function useGetIncompleteCourses() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", "incomplete-courses"],
		fn: getIncompleteCourses,
	});

	return { courses: data || [], isPending, isSuccess, isError, error };
}
