import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import style from "./Button.module.css";

function Button({ to, children, type, onClick, className, disabled }) {
	const commonProps = {
		className: `${style.button} ${style[type]} ${
			className ? className : ""
		}`,
		onClick,
		disabled,
	};

	return to ? (
		<Link to={to} {...commonProps}>
			{children}
		</Link>
	) : (
		<button {...commonProps}>{children}</button>
	);
}

Button.propTypes = {
	to: PropTypes.string,
	children: PropTypes.node.isRequired,
	type: PropTypes.oneOf([
		"accent",
		"primary",
		"secondary",
		"border",
		"bland",
		"none",
	]),
	onClick: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

export default Button;
