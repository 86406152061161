import { changePassword } from "@/features/auth/api";
import { useApiSend } from "@/hooks/useApi";

export function useChangePassword() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: ({ new_password1, new_password2 }) =>
			changePassword({ new_password1, new_password2 }),
		invalidateKeys: [["whoami"]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
