import { sendHelpTicket } from "@/features/auth/api";
import { useApiSend } from "@/hooks/useApi";

export function useSendHelpTicket() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) => sendHelpTicket(data),
	});

	return { sendHelpTicket: mutateAsync, isPending, error, isError };
}
