import { createPortal } from "react-dom";
import {
	cloneElement,
	createContext,
	useContext,
	useState,
	useEffect,
} from "react";

import style from "./Modal.module.css";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

const ModalContext = createContext();
let closeModal = () => {};

function Modal({ children }) {
	const [openName, setOpenName] = useState("");
	const close = () => setOpenName("");
	const open = setOpenName;

	closeModal = close;

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") {
				close();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<ModalContext.Provider value={{ openName, close, open }}>
			{children}
		</ModalContext.Provider>
	);
}

function Open({ children, opens: opensWindowName }) {
	const { open } = useContext(ModalContext);
	return cloneElement(children, { onClick: () => open(opensWindowName) });
}

function Close({ children }) {
	const { close } = useContext(ModalContext);
	return (
		<Button className={style.btn} onClick={close}>
			<Icon icon="fa-times" />
		</Button>
	);
}

function Window({ children, name, maxWidth = "800px" }) {
	const { openName, close } = useContext(ModalContext);
	if (name !== openName) return null;
	return createPortal(
		<div
			className={style.overlay}
			onClick={(e) => {
				e.target === e.currentTarget ? close() : null;
			}}
		>
			<div className={style.modal} style={{ "--max-width": maxWidth }}>
				<div>{cloneElement(children, { onCloseModal: close })}</div>
			</div>
		</div>,
		document.body
	);
}

Modal.Close = Close;
Modal.Open = Open;
Modal.Window = Window;
Modal.close = () => closeModal();

export default Modal;
