import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";

import AppLayout from "./layouts/appLayout/AppLayout";
import ProtectedRoute from "./layouts/adminRoutes/ProtectedRoute";
import PublicPages from "./layouts/publicPages/PublicPages";

import Home from "./pages/Home";
import AllCourses from "./pages/AllCourses";
import LearningPaths from "./pages/LearningPaths";
import PathPreview from "./pages/PathPreview";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PageNotFound from "./pages/PageNotFound";
import CourseWatching from "./pages/CourseWatching";
import Settings from "./pages/Settings";
import GoogleLoginReturn from "./pages/GoogleLoginReturn";
import { ThankYou } from "./features/payments/pages/ThankYou";
import { Course } from "./pages/Course";
import PaymentPlans from "./pages/PaymentPlans";
import ForgotPassword from "./pages/ForgotPassword";
import FullPageLoader from "./ui/fullPageLoader/FullPageLoader";
import Help from "./pages/Help";

const AdminRouter = React.lazy(() => import("./features/admin/AdminRouter"));

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 0,
			gcTime: 0,
		},
	},
});

function App() {
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<Routes>
						<Route element={<PublicPages />}>
							<Route element={<AppLayout />}>
								<Route path="/" element={<Home />} />
								<Route
									path="thank-you"
									element={<ThankYou />}
								/>
								<Route path="courses">
									<Route index element={<AllCourses />} />
								</Route>
								<Route
									path="paths"
									element={<LearningPaths />}
								/>

								<Route path="settings">
									<Route
										index
										element={
											<Settings currentTab="settings" />
										}
									/>
									<Route
										path="change-password"
										element={
											<Settings currentTab="change-password" />
										}
									/>
									<Route
										path="billing"
										element={
											<Settings currentTab="billing" />
										}
									/>
									<Route
										index
										element={
											<Settings currentTab="settings" />
										}
									/>
								</Route>
								<Route path="tutoring" element={<Home />} />
							</Route>
							<Route
								path="courses/:courseSlug"
								element={<Course />}
							/>
							<Route
								path="courses/:courseSlug/:moduleSlug/:unitSlug"
								element={<CourseWatching />}
							/>
							<Route
								path="paths/:pathSlug"
								element={<PathPreview />}
							/>
						</Route>
						<Route path="login">
							<Route index element={<Login />} />
							<Route
								path="google"
								element={<GoogleLoginReturn />}
							/>
						</Route>

						<Route path="register" element={<Register />} />
						<Route path="plans" element={<PaymentPlans />} />
						<Route
							path="forgot-password"
							element={<ForgotPassword />}
						/>
						<Route path="support" element={<Help />} />
						<Route
							path="admin/*"
							element={
								<ProtectedRoute>
									<React.Suspense
										fallback={<FullPageLoader />}
									>
										<AdminRouter />
									</React.Suspense>
								</ProtectedRoute>
							}
						/>
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</BrowserRouter>
				<Toaster position="bottom-center" reverseOrder={true} />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</>
	);
}

export default App;
