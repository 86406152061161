import { useEffect, useRef } from "react";
import remarkGfm from "remark-gfm";
import style from "./ChatBubble.module.css";
import Icon from "@/ui/icon/Icon";
import Markdown from "react-markdown";

function ChatBubble({ run }) {
	const thinking_statuses = [
		"created",
		"queued",
		"in_progress",
		"cancelling",
	];
	const error_statuses = [
		"requires_action",
		"cancelled",
		"failed",
		"incomplete",
		"incomplete",
	];
	const success_statuses = ["completed"];

	const bottomRef = useRef(null);

	useEffect(() => {
		if (thinking_statuses.includes(run?.status))
			if (bottomRef.current) bottomRef.current?.scrollIntoView();
	}, [run, bottomRef]);

	return (
		<div>
			<div className={`${style.bubbleHolder} ${style.human}`}>
				<pre>{run.message}</pre>
			</div>
			<div className={`${style.bubbleHolder} ${style.ai}`}>
				<div className={style.aiBubble}>
					<div className={style.aiAvatar}>
						<div className={style.icoHolder}>
							<Icon icon="fa-user-robot" />
						</div>
						<div className={style.name}>
							<span>Dekamas Inteligentni Asistent</span>
						</div>
					</div>
					<div
						className={`${style.aiMessage} prose prose-code:font-mono`}
					>
						{run.response &&
							(thinking_statuses.includes(run.status) ||
								success_statuses.includes(run.status)) && (
								<Markdown remarkPlugins={[remarkGfm]}>
									{run.response}
								</Markdown>
							)}
						{thinking_statuses.includes(run.status) &&
							!run.response && (
								<div className={style.pending}>
									<span className={style.loader}></span>
									<span className={style.loadingText}>
										Dia razmišlja... 🤔
									</span>
								</div>
							)}
						{error_statuses.includes(run.status) && (
							<div className={style.error}>
								<Icon icon="fa-exclamation-circle" />
								<span>Žao nam je, došlo je do greške.</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<div ref={bottomRef}></div>
		</div>
	);
}

export default ChatBubble;
