import style from "./Certificate.module.css";

function Certificate({ size, background, className }) {
	return (
		<div
			className={`${style.certificateHolder} ${className}`}
			style={{ "--size": `${size}em` }}
		>
			<div className={style.certificate}>
				<div className={style.left}>
					<div className={style.text}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className={style.initials}>
						<span></span>
						<span></span>
					</div>
				</div>
				<div className={style.right}>
					<div className={style.strip}>
						<span>D</span>
					</div>
				</div>
			</div>
			{background && (
				<>
					<div className={style.back}></div>
					<div className={style.back}></div>
				</>
			)}
		</div>
	);
}

export default Certificate;
