import { sendForgotPasswordCode } from "@/features/auth/api";
import { useApiSend } from "@/hooks/useApi";

export function useSendForgotPasswordCode() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: sendForgotPasswordCode,
	});

	return { save: mutateAsync, isPending, error, isError };
}
