import style from "./AboutCourseSection.module.css";

import Icon from "@/ui/icon/Icon";

function AboutCourseSection({ unit }) {
	return (
		<div className={style.grid}>
			<div
				className={style.description}
				dangerouslySetInnerHTML={{
					__html: unit?.course?.description,
				}}
			/>
			<div className={style.other}>
				<Tutors lecturers={unit.course.lecturers} />
				<CourseStats course={unit.course} />
			</div>
		</div>
	);
}

// TODO: use lecturer UUID instead of name as key - this is fine for now
function Tutors({ lecturers }) {
	return (
		<div className={style.tutor}>
			<h2>{lecturers.length > 1 ? "Instruktori" : "Instruktor"}</h2>
			{lecturers.map((lecturer) => (
				<div key={lecturer.name}>
					<div className={style.user}>
						<div className={style.imgHolder}>
							<img
								src={lecturer.picture_url}
								alt={lecturer.name}
							/>
						</div>
						<div className={style.info}>
							<div className={style.name}>{lecturer.name}</div>
							<div className={style.roll}>
								{lecturer.job_title}
							</div>
						</div>
					</div>
					<div className={style.tutorDesc}>
						<p>{lecturer.bio}</p>
					</div>
				</div>
			))}
		</div>
	);
}

function CourseStats({ course }) {
	return (
		<div className={style.courseStats}>
			<h2>Kategorije</h2>
			<div className={style.skills}>
				{course?.categories.map((category) => (
					<span key={category.slug}>{category.name}</span>
				))}
			</div>
		</div>
	);
}

export default AboutCourseSection;
