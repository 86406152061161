import { request } from "@/services/network";

export async function getCourseReviews({ course }) {
	return request({
		url: `/courses/${course.slug}/reviews/`,
		method: "GET",
	});
}

export async function postCourseReview({ course_slug, stars, content }) {
	return request({
		url: `/courses/${course_slug}/reviews/`,
		method: "POST",
		data: {
			stars,
			content,
		},
	});
}

export async function deleteCourseReview({ course_slug }) {
	return request({
		url: `/courses/${course_slug}/reviews/`,
		method: "DELETE",
	});
}
