import style from "./PathCourses.module.css";

import PathCourseCard from "../pathCourseCard/PathCourseCard";
import { useContext } from "react";
import PathPreviewContext from "../../contexts/PathPreviewContext";

function PathCourses() {
	const { path } = useContext(PathPreviewContext);

	return (
		<div className={style.holder}>
			{path?.courses &&
				path.courses.map((course, index) => (
					<PathCourseCard
						key={course.slug}
						index={index}
						course={course}
					/>
				))}
		</div>
	);
}

export default PathCourses;
