import style from "./SettingsTabs.module.css";
import { Link } from "react-router-dom";

import Icon from "@/ui/icon/Icon";

function SettingsTabs({ currentTab }) {
	const settingsTabs = [
		{
			name: "Postavke",
			path: "/settings/",
			icon: "fa-cog",
		},
		{
			name: "Promijenite lozinku",
			path: "/settings/change-password/",
			icon: "fa-lock",
		},
		{
			name: "Pretplata",
			path: "/settings/billing/",
			icon: "fa-credit-card",
		},
	];

	return (
		<div className={style.tabsHolder}>
			{settingsTabs.map((tab) => (
				<SettingsTab
					key={tab.name}
					path={tab.path}
					isCurrent={
						currentTab == tab.path.split("/").filter(Boolean).pop()
					}
				>
					<Icon icon={tab.icon} />
					<span>{tab.name}</span>
				</SettingsTab>
			))}
		</div>
	);
}

function SettingsTab({ path, isCurrent, children }) {
	return (
		<Link
			to={path}
			className={`${style.tab} ${isCurrent ? style.active : ""}`}
		>
			{children}
		</Link>
	);
}

export default SettingsTabs;
