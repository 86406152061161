import { request } from "@/services/network";

export async function getPaths() {
	return request({
		url: "/paths/",
		method: "GET",
	});
}

export async function getPath({ pathSlug }) {
	return request({
		url: `/paths/${pathSlug}`,
		method: "GET",
	});
}
