import React, { useEffect, useRef } from "react";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginWithGoogleOAuthCode } from "@/features/auth/hooks/useLoginWithGoogleOAuthCode";

function GoogleLoginReturn() {
	const [searchParams] = useSearchParams();

	const { login } = useLoginWithGoogleOAuthCode();

	const requestAlreadySent = useRef(false);

	useEffect(() => {
		async function loginWithCode() {
			if (!requestAlreadySent.current) {
				requestAlreadySent.current = true;

				const state = searchParams.get("state");
				const code = searchParams.get("code");

				await login({ code, state });
			}
		}

		loginWithCode();
	}, [requestAlreadySent]);

	return <FullPageLoader />;
}

export default GoogleLoginReturn;
