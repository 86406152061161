import { useApiGet } from "@/hooks/useApi";
import { getSearchIndex } from "../api";

export function useSearchIndex() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["search", "index"],
		fn: getSearchIndex,
	});

	return { searchIndex: data || [], isPending, isSuccess, isError, error };
}
