import { postCourseReview } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function usePostCourseReview({ course }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) => postCourseReview({ course_slug: course.slug, ...data }),
		invalidateKeys: [["reviews", course.slug]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
