import { useContext, useEffect, useRef, useState } from "react";
import style from "./AiChatSession.module.css";
import ChatBubble from "../chatBubble/ChatBubble";
import AIPopupContext from "../../contexts/AIPopupContext";

// here should all the messages be displayed
function AiChatSession() {
	const { runs, currentThread } = useContext(AIPopupContext);

	const ref = useRef(null);

	useEffect(() => {
		console.log(ref.current);
		if (ref.current) {
			console.log("Scrolling", ref.current);
			ref.current?.scrollIntoView();
		}
	}, [runs, currentThread, ref]);

	return (
		<div className={style.inbox}>
			{runs.map((run) => (
				<ChatBubble key={run.uuid} run={run} />
			))}
			<div ref={ref}></div>
		</div>
	);
}

export default AiChatSession;
