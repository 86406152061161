import { useForgotPasswordCode } from "@/features/auth/api";
import { useApiSend } from "@/hooks/useApi";
import { successToast } from "@/ui/toast/Toast";
import { AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME } from "@/utils/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export function useUseForgotPasswordCode() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: useForgotPasswordCode,
		invalidateKeys: [["whoami"]],
		resetKeys: [["whoami"]],
	});

	const useCodeMutate = async function (data) {
		const res = await mutateAsync(data);

		localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME, res.token);

		queryClient.resetQueries({ queryKey: ["whoami"] });

		successToast("Uspješno ste postavili novu lozinku.");

		if (res.has_completed_registration) {
			navigate("/", { replace: true });
		} else {
			navigate("/register", { replace: true });
		}
	};

	return { save: useCodeMutate, isPending, error, isError };
}
