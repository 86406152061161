import { useState } from "react";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/ui/select/Select";

// Filter Component
function ReviewsFilter({ selectedReviewSort, setSelectedReviewSort }) {
	return (
		<Select
			value={selectedReviewSort}
			onValueChange={setSelectedReviewSort}
		>
			<SelectTrigger>
				<SelectValue placeholder="Sortirajte" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Sortiraj po:</SelectLabel>
					<SelectItem value="most-recent">Najnovije</SelectItem>
					<SelectItem value="highest-rated">
						Najbolje ocijenjeno
					</SelectItem>
					<SelectItem value="lowest-rated">
						Najslabije ocijenjeno
					</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}

export default ReviewsFilter;
