import { useFormContext } from "react-hook-form";
import Label from "./Label";
import InputError from "./InputError";

// TODO: remove tailwindcss here

export default function FormTextArea({
	name,
	label,
	required,
	showErrors = true,
	...props
}) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	return (
		<div className="flex flex-col">
			{label && (
				<Label required={required} htmlFor={name}>
					{label}
				</Label>
			)}
			<textarea
				className="mt-1.5 flex-grow border p-2 text-sm border-gray-300 focus-visible:border-gray-500"
				id={name}
				{...props}
				{...register(name)}
			/>
			{showErrors && errors[name] && (
				<InputError>{errors[name].message}</InputError>
			)}
		</div>
	);
}
