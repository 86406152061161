import style from "./ShortcutKey.module.css";
import Icon from "@/ui/icon/Icon";

const symbolsMap = {
	up: "fa-arrow-up",
	down: "fa-arrow-down",
	left: "fa-arrow-left",
	right: "fa-arrow-right",
	return: "fa-arrow-turn-down-left",
	command: "fa-command",
	esc: "esc",
	k: "fa-k",
	a: "fa-a",
	b: "fa-b",
	c: "fa-c",
	d: "fa-d",
};

function ShortcutKey({ symbols, name }) {
	return (
		<div className={style.shortcutKey}>
			<div className={style.buttonsHolder}>
				{symbols.map((symbol) => (
					<div key={symbol} className={style.button}>
						{symbolsMap[symbol].includes("fa-") ? (
							<Icon icon={symbolsMap[symbol]} />
						) : (
							<span className={style[symbolsMap[symbol]]}></span>
						)}
					</div>
				))}
			</div>
			{name && <span className={style.name}>{name}</span>}
		</div>
	);
}

export default ShortcutKey;
