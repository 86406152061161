import React, { useEffect, useCallback } from "react";
import style from "./CheckButton.module.css";
import Button from "@/ui/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseUnits } from "@/features/courses/hooks/useGetCourseUnits";

function CheckButton({ dispatch, answer, index, questions }) {
	const { courseSlug, moduleSlug, unitSlug } = useParams();
	const { course, modules, isPending, isSuccess, isError, error } =
		useGetCourseUnits({ courseSlug });

	const navigate = useNavigate();

	const handleKeyDown = useCallback(
		async (event) => {
			if (event.key === "Enter" && answer) {
				dispatch({ type: "checkAnswer", payload: answer });
			}
		},
		[answer, dispatch]
	);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const handleClick = useCallback(() => {
		dispatch({ type: "checkAnswer", payload: answer });
	}, [answer, dispatch]);

	// ovde sad treba da  kaze ako je pitanje posldje da ima nastavi dalje dugme, a ako nema dalje da ide go back to home

	const openNextUnit = function () {
		let module_idx = -1;
		let unit_idx = -1;
		for (let i = 0; i < modules.length; i++) {
			if (modules[i].slug == moduleSlug) {
				module_idx = i;
				break;
			}
		}

		if (module_idx == -1) return navigate("/");

		for (let i = 0; i < modules[module_idx].units.length; i++) {
			if (modules[module_idx].units[i].slug == unitSlug) {
				unit_idx = i;
				break;
			}
		}

		if (unit_idx == -1) return navigate("/");

		console.log(unit_idx);

		// return the next unit in the current module
		if (unit_idx + 1 < modules[module_idx].units.length)
			return navigate(
				`/courses/${courseSlug}/${modules[module_idx].slug}/${
					modules[module_idx].units[unit_idx + 1].slug
				}`
			);

		// the first unit in the next module
		for (let i = module_idx + 1; i < modules.length; i++)
			if (modules[i].units.length > 0)
				return navigate(
					`/courses/${courseSlug}/${modules[i].slug}/${modules[i].units[0].slug}`
				);

		return navigate("/");
	};

	return index == questions.length ? (
		<Button className={style.check} onClick={openNextUnit}>
			Nastavi dalje
		</Button>
	) : (
		<Button
			className={style.check}
			onClick={handleClick}
			disabled={!answer}
		>
			Provjeri
		</Button>
	);
}

export default CheckButton;
