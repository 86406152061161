import { deleteCourseReview } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function useDeleteCourseReview({ course }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) => deleteCourseReview({ course_slug: course.slug }),
		invalidateKeys: [["reviews", course.slug]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
