import { successToast } from "@/ui/toast/Toast";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export function ThankYou() {
	const queryClient = useQueryClient();
	queryClient.invalidateQueries({ queryKey: ["whoami"] });
	queryClient.invalidateQueries({ queryKey: ["courses"] });
	successToast("Hvala vam na pretplati!");
	return <Navigate to="/" />;
}
