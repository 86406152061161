import style from "./IncompleteCourseCard.module.css";
import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";
import { Link } from "react-router-dom";

import { timeAgo } from "@/utils/helpers";

function IncompleteCourseCard({ course }) {
	const { videos_completed, quizzes_completed, total_units } =
		course.courseProgress;
	const { module, slug: videoSlug, name: videoName } = course.video;
	const { course: courseDetails } = module;
	const {
		name: courseName,
		slug: courseSlug,
		thumbnail: courseThumbnail,
	} = courseDetails;

	const units_completed = videos_completed + quizzes_completed;
	const units_percent = Math.round((100 * units_completed) / total_units);

	return (
		<section className={style.continue}>
			<div className={style.body}>
				<div className={style.continueCourse}>
					<Link
						to={`/courses/${courseSlug}/${module.slug}/${videoSlug}/`}
						className={style.thumbnailHolder}
					>
						<div
							className={style.thumbnail}
							style={{
								"--image": `url(${courseThumbnail})`,
							}}
						></div>
					</Link>
					<div className={style.info}>
						<h3>{courseName}</h3>
						<div className={style.labels}>
							<div className={style.label}>
								<Icon icon="fa-clock" />
								<span>Posljednji put posjetili: </span>
								<span>
									{timeAgo(
										new Date(
											course?.courseLastWatched
										).getTime()
									)}
								</span>
							</div>
						</div>
						<div className={style.progressHolder}>
							<div className={style.progress}>
								<div
									className={style.progressBar}
									style={{
										"--progress": `${units_percent}%`,
									}}
								>
									<div className={style.circle}></div>
								</div>
							</div>
							<div className={style.progressStatus}>
								<div>
									{units_completed} / {total_units}
								</div>
								<div>{units_percent}%</div>
							</div>
						</div>
						<div className={style.footer}>
							<div className={style.currently}>
								<div className={style.icoHolder}>
									<Icon icon="fa-play" />
								</div>
								<div className={style.subHeader}>
									<span>Trenutno gledate</span>
									<span>{videoName}</span>
								</div>
							</div>
							<Button
								to={`/courses/${courseSlug}/${module.slug}/${videoSlug}/`}
								type="accent"
							>
								<span>Nastavi</span>
								<Icon icon="fa-arrow-right" />
							</Button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default IncompleteCourseCard;
