import { useContext } from "react";
import style from "./AiChatHistory.module.css";
import AIPopupContext from "../../contexts/AIPopupContext";

function AiChatHistory() {
	const { threads, currentThread, setCurrentThread } =
		useContext(AIPopupContext);

	return (
		<div className={style.chatHistory}>
			<div className={style.segment}>
				<h3>Prošli razgovori</h3>
				{/* // for each chat session */}
				{threads.map((thread) => (
					<div
						key={thread.uuid}
						title={thread.name}
						className={
							thread.uuid == currentThread
								? `${style.item} ${style.active}`
								: style.item
						}
						onClick={() => setCurrentThread(thread.uuid)}
					>
						<span>{thread.name}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default AiChatHistory;
