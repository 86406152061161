import React, { Children } from "react";
import { Link } from "react-router-dom";

import style from "./ForgotPasswordStep.module.css";

import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";

function ForgotPasswordStep({
	title,
	description,
	icon,
	actionName,
	action,
	children,
	button,
}) {
	return (
		<div className={style.content}>
			<div className={style.header}>
				<div className={style.icoHolder}>
					<Icon icon={icon} />
				</div>
				<h2>{title}</h2>
				<p>{description}</p>
			</div>
			<div className={style.body}>{children}</div>
			<div className={style.footer}>
				{!button && (
					<Button
						className={style.btn}
						type="accent"
						onClick={action}
					>
						{actionName}
					</Button>
				)}
				<Link to="/login">
					<Icon icon="fa-arrow-left" /> Vrati se nazad
				</Link>
			</div>
		</div>
	);
}

export default ForgotPasswordStep;
